<template>
  <v-card class="mt-2 mr-2 ml-2 shadow rounded-lg pointer" @click="viewEvent">
    <v-card-title class="d-block p-b-2">
      <h3 class="text-center ellipsis min-w-full text-base">
        {{ name }}
      </h3>
      <v-divider class="m-y-2"/>
    </v-card-title>
    <div class="p-3">
      <v-row class="pr-2 pl-2">
        <v-col class="p-t-0" cols="4" md="4">
          <view-image
              :defaultImage="'event'"
              :image="image_path"
              style="border-radius: 0.5rem"
          />
        </v-col>
        <v-col class="p-0"  md="8">
          <div>
            <SvgIcon :text="start_date | dateformat" class="gap-x-4 m-b-3 font-medium">
              <template v-slot:icon>
                <CalendarIcon/>
              </template>
            </SvgIcon>
            <SvgIcon class="gap-x-4 m-b-3">
              <template v-slot:icon>
                <AttendanceIcon/>
              </template>
              <div>
                <p class="m-b-0 font-medium">
                  {{ (participants||0) + ' / ' + total_tickets }}
                </p>
                <p class="m-b-0 font-medium text-xs">
                  Attendance
                </p>
              </div>
            </SvgIcon>
            <SvgIcon class="gap-x-4 m-b-3" v-if="checkReadPermission($modules.events.sales.slug)">
              <template v-slot:icon>
                <SalesIcon/>
              </template>
              <div>
                <p class="m-b-0 font-medium">
                  {{ Number(sales) | toCurrency }}
                </p>
                <p class="m-b-0 font-medium text-xs">
                  Sales
                </p>
              </div>
            </SvgIcon>
          </div>
        </v-col>
      </v-row>
      <v-divider class="m-y-3"/>
      <p class="line-clamp-3 text-xs text-light-black" style="min-height: 47px">
        <template  v-if="description">
          {{ description.slice(0, 250) }}
        </template>
      </p>
    </div>
  </v-card>
</template>

<script>
import SalesIcon from '@/assets/images/events/sales-icon.svg';
import CalendarIcon from '@/assets/images/events/calendar-icon.svg';
import AttendanceIcon from '@/assets/images/events/attendance-icon.svg';
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: {SvgIcon, SalesIcon, CalendarIcon, AttendanceIcon},
  props: {
    description: {type: String, default: ""},
    start_date: {type: String, default: ""},
    end_date: {type: String, default: ""},
    venue_service: {type: String, default: ""},
    id: {type: Number},
    image_path: {type: String, default: undefined},
    location: {type: String, default: ""},
    name: {type: String, default: ""},
    participants: {type: Number, default: 0},
    sales: {type: Number, default: 0},
    status_id: {type: Number, default: 1},
    total_tickets: {type: Number, default: 0}
  },
  methods: {
    editEvent() {
      this.$router.push({
        name: "EventsEdit",
        params: {data: btoa(this.id)},
      });
    },
    viewEvent() {
      this.$router.push({
        name: "EventsView",
        params: {data: btoa(this.id)},
      });
    },
    cancelEvent() {
      this.$emit("cancelEvent", this.id);
    },
  },
};
</script>

