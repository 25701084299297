<template>
  <v-container fluid>
    <div class="d-flex justify-space-between align-center">
      <v-autocomplete
          v-model="venueServiceIds"
          :hide-details="true"
          :items="venueServices"
          background-color="#fff"
          class="q-autocomplete shadow-0"
          item-text="name"
          item-value="venue_service_id"
          multiple
          outlined
          placeholder="Select Service"
          style="max-width: 250px"
          @change="getTimeLineDataList(currentYear, true)"
      >
        <template
            v-if="venueServiceIds.length === venueServices.length"
            v-slot:selection="{ index }"
        >
          <span v-if="index === 0">All Services</span>
        </template>
        <template v-else v-slot:selection="{ item, index }">
          <span v-if="index === 0">{{ item.name }}</span>
          <span v-if="index === 1">, {{ item.name }}</span>
          <span v-if="index === 2">, ...</span>
        </template>

        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon
                  :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"
              >{{ icon() }}
              </v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
      <v-btn-toggle
          v-model="toggle_mode"
          borderless
          class="q-tabs shadow-0 bordered"
          mandatory
          tile
      >
        <v-btn value="active">Upcoming</v-btn>
        <v-btn value="completed">Completed</v-btn>
        <v-btn value="pending">Pending</v-btn>
        <v-btn value="cancelled">Cancelled</v-btn>
      </v-btn-toggle>

      <div class="d-flex justify-space-between gap-x-2">
      <v-btn
          v-if="checkWritePermission($modules.events.management.slug)"
          class="bg-blue text-white text-capitalize svg-stroke-white"
          height="48"
          @click="openConfigurations"
      >
        <ConfigIcon class="mr-2"/>
        Config
      </v-btn>

      <v-btn
          v-if="checkWritePermission($modules.events.management.slug)"
          class="bg-blue text-white text-capitalize"
          height="48"
          @click="addNewEvents"
      >
        <AddIcon class="mr-2"/>
        Add Event
      </v-btn>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex gap-x-4 p-y-8">
        <v-btn
            v-for="(yr,yi) in timeLineData"
            :key="yi"
            :class="{'active':yr.year === year.year}"
            class="q-btn-secondary-outlined"
            outlined
            @click="year = {...yr,status:true}"
        >
          {{ yr.year }}
        </v-btn>
      </div>

      <v-text-field
          v-model="nameFilter"
          class="q-text-field shadow-0 bg-white"
          hide-details="auto"
          outlined
          placeholder="Search"
          @change="searchByName"
          @keydown.enter="searchByName"
          @click:clear="clearSearch"
          style="max-width: 300px"
          dense
      >
        <template #prepend-inner>
          <SearchIcon/>
        </template>
      </v-text-field>
    </div>
    <template v-if="timeLineData.length>0">
      <v-row v-if="year">
        <v-expansion-panels
            v-model="year.tabs[toggle_mode]"
            accordion
            flat
            hover
            light
            multiple
            tile
        >
          <template
              v-if="typeof year[toggle_mode] != 'undefined' && year.status == true"
          >
            <template v-for="(month, mi) in year[toggle_mode].months">
              <v-expansion-panel
                  :key="mi"
                  class="m-b-8"
                  @change="getMonthWiseData(year.year, year.yearIndex, month, mi)"
              >
                <v-expansion-panel-header class="m-l-0 m-b-4 px-4 bg-white py-0" style="min-height: 48px">
                  <span class="event-month-name">{{ month }}</span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row no-gutters>
                    <v-col
                        v-for="eventData in year[toggle_mode][month]"
                        :key="eventData.id"
                        md="4"
                        sm="6"
                        xl="3"
                    >
                      <event-widget
                          :monthIndex="mi"
                          :monthName="month"
                          :year="year.year"
                          :yearIndex="year.yearIndex"
                          v-bind="eventData"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </template>
          <template v-else-if="year.status">
            <h3>
              No
              {{ toggle_mode }}
              events in {{ year.year }}
              {{ nameFilter ? `for search ' ${nameFilter}'` : "" }}
            </h3>
          </template>
        </v-expansion-panels>
      </v-row>
    </template>
    <v-row v-else>
      <h3 class="text-center">
        No Events found
      </h3>
    </v-row>
  </v-container>
</template>
<script>
import EventWidget from "@/components/Event/EventWidget";
import moment from "moment";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import SearchIcon from "@/assets/images/events/search.svg";
import ConfigIcon from "@/assets/images/trainers/cog_icon.svg";

export default {
  components: {AddIcon,SearchIcon, EventWidget,ConfigIcon},
  data() {
    return {
      toggle_mode: "active",
      venueServiceIds: [],
      currentYear: Number(moment().format("YYYY")),
      currentMonth: Number(moment().format("M")) - 1,
      search: "",
      timeLineData: [],
      nameFilter: null,
      year: null,
      debounceTimeout: null,
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter((service) => service.name != "POS");
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then(() => {
        this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
        );
        this.getTimeLineDataList(this.currentYear, true);
      });
    }
    else{
      this.venueServiceIds = this.venueServices.map(
          (item) => item.venue_service_id
      );
      this.getTimeLineDataList(this.currentYear, true);
    }
    if (!this.$store.getters.getFieldConfigurations.status) {
      this.$store.dispatch("loadEventFieldConfigurations", "Event").then(() => {});
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.venueServiceIds.length == this.venueServices.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
              (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.getTimeLineDataList(this.currentYear, true);
      });
    },
    icon() {
      if (this.venueServiceIds.length == this.venueServiceIds)
        return "mdi-close-box";
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      return "mdi-minus-box";
    },
    openConfigurations(){
      this.$router.push(
          {
            name: "EventsConfigurations",
          },
          () => {
          }
      );
    },
    addNewEvents() {
      this.$router.push(
          {
            name: "EventsAdd",
          },
          () => {
          }
      );
    },
    clearSearch() {
      setTimeout(() => {
        this.getTimeLineDataList(this.currentYear, true);
      }, 100);
    },
    getTimeLineDataList(year, initial) {
      this.showLoader("Loading");
      let url = `venues/events/timeline?year=${year}`;
      url += `${this.nameFilter ? "&name=" + this.nameFilter : ""}`;
      url += this.venueServiceIds
          .map((item, index) => `&venue_service_ids[${index}]=${item}`)
          .join(",");
      if (initial == true) {
        url += "&initial=1";
      }
      this.$http
          .get(url)
          .then((response) => {
            if (response.status == 200) {
              if (initial == true) {
                this.timeLineData = response.data.data;
                for (let index = 0; index < this.timeLineData.length; index++) {
                  this.timeLineData[index].tabs = {};
                  this.timeLineData[index].tabs.active = [];
                  this.timeLineData[index].tabs.completed = [];
                  this.timeLineData[index].tabs.pending = [];
                  this.timeLineData[index].tabs.cancelled = [];
                  this.timeLineData[index].yearIndex = index;
                }
                let index = this.timeLineData.findIndex(
                    (item) => item.active != null
                );
                if (index != -1) {
                  let data = this.timeLineData[index];
                  this.getMonthWiseData(
                      data.year,
                      index,
                      data.active.months[0],
                      0
                  );
                }
                this.year = this.timeLineData[0];
                this.year.status = true;
              } else {
                let data = response.data.data;
                data.tabs = {};
                data.tabs.active = [];
                data.tabs.completed = [];
                data.tabs.pending = [];
                data.tabs.cancelled = [];
                data.yearIndex = this.timeLineData.length;
                this.timeLineData.push(data);
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getMonthWiseData(currentYear, yearIndex, monthName, monthIndex, refresh) {
      console.log(currentYear, yearIndex, monthName, monthIndex, refresh)
      if (refresh == null) {
        let checkOpenTab = this.timeLineData[yearIndex].tabs[
            this.toggle_mode
            ].findIndex((index) => index == monthIndex);
        if (checkOpenTab != -1) {
          this.timeLineData[yearIndex].tabs[this.toggle_mode].slice(
              checkOpenTab,
              1
          );
          return;
        }
      }
      this.showLoader("Loading");
      let month = moment().month(monthName).format("M");
      let url = `status=${this.toggle_mode}&year=${currentYear}&month=${month}${
          this.nameFilter ? "&name=" + this.nameFilter : ""
      }`;
      url += this.venueServiceIds
          .map((item, index) => `&venue_service_ids[${index}]=${item}`)
          .join(",");
      this.$http
          .get(`venues/events?${url}`)
          .then((response) => {
            if (response.status == 200) {
              if (!this.timeLineData[yearIndex][this.toggle_mode]) {
                this.timeLineData[yearIndex][this.toggle_mode] = {};
              }
              this.timeLineData[yearIndex][this.toggle_mode][monthName] = [];
              this.timeLineData[yearIndex][this.toggle_mode][monthName] =
                  response.data.data;
              let checkOpenTab = this.timeLineData[yearIndex].tabs[
                  this.toggle_mode
                  ].findIndex((index) => index == monthIndex);
              if (checkOpenTab == -1) {
                this.timeLineData[yearIndex].tabs[this.toggle_mode].push(
                    monthIndex
                );
              }
              this.$forceUpdate();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    searchByName() {
      this.getTimeLineDataList(this.currentYear, true);
    }
  },
};
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}

/* TODO:: Move to global once design completed */
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: unset !important;
}
</style>
